/** @jsxImportSource react */
import { userExact } from '@common/helpers/role';
import SearchBoxContainer from '@react/search/components/SeachBoxContainer';
import { eventNames, useEvents } from '@react/services/events';
import HeaderLoggedInArea from '@react/shared/components/Header/components/headerLoggedInArea';
import Logo from '@react/shared/components/Logo';
import { Button, DropdownButton, Link } from '@react/system';
import { CUSTOMER_ROLE } from '@sly/core/constants/roles';
import HeaderAgentPhNo from '@sly/frontend/common/components/HeaderAgentPhNo';
import { Chevron, Close, Menu, Seniorly } from '@sly/icons/react';
import cx from 'classnames';
import dynamic from 'next/dynamic';
import { array, arrayOf, bool, func, oneOf, shape, string } from 'prop-types';
import React, { forwardRef, Fragment, useRef, useState } from 'react';
import { isBrowser } from 'sly/config';

const ReactTooltip = dynamic(() => import('react-tooltip'), {
  ssr: false,
});

const NumbersTooltip = ({
  children,
  text = 'Talk with a local advisor for free',
}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      className='relative inline-block'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
      {isHovered && (
        <div className='font-b-xs absolute top-6 left-1/2 z-50 max-w-xs -translate-x-1/2 rounded-lg bg-slate-base p-3 text-sm text-white shadow-subtle'>
          {text}
        </div>
      )}
    </div>
  );
};

const DropdownMenu = forwardRef(
  ({ loggedInMenuItems, lowerMenuItems, isOpen, setIsOpen, ...props }, ref) => {
    loggedInMenuItems = loggedInMenuItems.sort((a, b) => a.section - b.section);

    return (
      <div
        ref={ref}
        className={cx(
          'dropdownMenu',
          'w-full',
          'h-full',
          'absolute',
          'left-0',
          'z-nav',
          'p-m',
          'pt-0',
          'bg-white',
          'md:px-l',
          'lg:px-0',
          'lg:py-m',
          'lg:w-[16.5rem]',
          'lg:top-[4rem]',
          'lg:left-auto',
          'lg:right-l',
          'lg:shadow-xl',
          'lg:border',
          'lg:border-slate-lighter-90',
          'lg:h-[unset]'
        )}
        {...props}
        data-testid='dropdownMenu'
      >
        <div
          className='lg:hidden'
          data-tp-id='gen-div-65d3f1aa-b874-46e5-a3ef-e25202e98b3b'
        >
          {lowerMenuItems.map((innerProps) => (
            <LowerMenuItem
              key={innerProps.name}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              {...innerProps}
            />
          ))}
        </div>
        <hr className='my-m mb-l lg:hidden' />
        {/* <Link
          eventProps={{ location: 'mobile menu' }}
          className='lg:hidden'
          to={HEADER_PHONE_NUMBER_LINK}
          data-tp-id='gen-Link-6b5f7830-8180-4d11-891e-3d094b7b620a'
        >
          {HEADER_PHONE_NUMBER}
        </Link>
        <hr className='my-m mt-l lg:hidden' /> */}
        {loggedInMenuItems.map(({ Icon, ...item }, i) => {
          const className = cx(
            'flex',
            'flex-row',
            'items-center',
            'py-m',
            'px-0',
            'bg-white',
            'gap-s',
            item.color ? item.color : '!text-slate-lighter-40',
            'font-b-s',
            item.hideInBigScreen && 'lg:hidden',
            'hover:bg-viridian-lighter-90',
            'hover:!text-viridian-base',
            'lg:p-s'
          );

          return (
            <Fragment key={item.name}>
              {i > 0 && loggedInMenuItems[i - 1].section !== item.section && (
                <hr
                  className={cx(
                    'my-xs mx-0 border-0 border-t-[0.062rem] border-slate-lighter-90',
                    i > 0 &&
                      loggedInMenuItems[i - 1].hideInBigScreen &&
                      'lg:hidden'
                  )}
                />
              )}
              <Link
                className={className}
                onClick={item.onClick}
                key={item.to}
                size='caption'
                to={item.to}
                eventProps={item.eventProps}
                data-tp-id={
                  item['data-tp-id'] ||
                  'gen-Link-50cce958-11ff-4e84-9370-6638e129d3eb'
                }
              >
                {Icon && (
                  <Icon
                    size='s'
                    marginRight='s'
                    data-tp-id='gen-Icon-48e8031e-20de-4be1-bfc0-1d75954a0b57'
                    className='text-slate-lighter-40'
                  />
                )}
                <span data-tp-id='gen-span-2c937cc4-c62e-453c-bcaa-778cc4d3844e'>
                  {item.name}
                </span>
              </Link>
            </Fragment>
          );
        })}
      </div>
    );
  }
);

const HeaderItem = ({
  to,
  href,
  name,
  onClick,
  eventProps,
  isTooltip,
  hoverEvent,
  tooltipText,
}) => {
  const classes = cx(['font-b-m', 'mr-l', 'hover:border-b-4']);

  const { events } = useEvents();
  const sendHoverEvent = (info) => {
    events.track(eventNames.InfoHovered, {
      info,
      location: 'header',
    });
  };

  const toolTip = {};
  if (isTooltip) {
    toolTip['data-tip'] = 'Concierge';
    toolTip['data-for'] = 'fafConcierge';
  }

  return (
    <>
      <Link
        key={name}
        to={to}
        href={href}
        eventProps={eventProps}
        onClick={onClick}
        className={classes}
        data-tp-id='gen-Link-8553d318-873f-4a00-af4f-f59f43f9f09b'
        {...toolTip}
      >
        {name}
      </Link>
      {isBrowser && isTooltip && (
        <ReactTooltip
          className='max-w-xs whitespace-normal bg-white p-xs text-slate-base shadow-sm'
          afterShow={() => sendHoverEvent(hoverEvent)}
          place='bottom'
          id='fafConcierge'
          type='light'
          effect='solid'
          multiline
        >
          {tooltipText}
        </ReactTooltip>
      )}
    </>
  );
};

const LowerMenuItem = ({
  name,
  leftLinks,
  rightLinks,
  leftTitle,
  rightTitle,
  description,
  eventProps,
  isOpen,
  setIsOpen,
}) => {
  return (
    <>
      <DropdownButton
        onClick={() => {
          if (isOpen === name) {
            setIsOpen(null);
          } else {
            setIsOpen(name);
          }
        }}
        isOpen={isOpen === name}
        className={cx(
          '!font-t-s lg:!font-b-s flex w-full justify-between !whitespace-normal py-m px-0 lg:w-auto lg:border-b-4 lg:border-transparent lg:!p-s lg:!pb-xs',
          isOpen === name && 'lg:!border-viridian-base'
        )}
        eventProps={{
          ...eventProps,
          text: eventProps?.text + (isOpen === name ? ' - Close' : ' - Open'),
        }}
      >
        {name}
      </DropdownButton>
      <div
        className={cx(
          'ml-auto mr-auto flex justify-center lg:absolute lg:left-[50%] lg:top-[120px] lg:z-[101] lg:translate-x-[-50%]',
          isOpen !== name && 'hidden'
        )}
        data-tp-id='gen-div-1c7e674b-fc7e-436a-a7cc-47628721ac97'
      >
        <div
          className='font-b-m -mx-m grid gap-m whitespace-normal bg-slate-lighter-97 px-m pt-m pb-s text-left md:-mx-l md:px-l lg:mx-0 lg:grid-cols-[26rem_37rem] lg:gap-l lg:rounded-lg lg:bg-white lg:py-l lg:drop-shadow-lg'
          data-tp-id='gen-div-b0e78b22-02bf-4d74-8856-7306fbc47022'
        >
          <div
            className='lg:px-m'
            data-tp-id='gen-div-62b53712-db08-4532-92d7-fff744c85289'
          >
            <div
              className='font-t-s hidden  py-m lg:block'
              data-tp-id='gen-div-e7ada487-0c30-4cba-847b-8ff7660d3fb6'
            >
              {leftTitle}
            </div>
            <div
              className='mb-l'
              data-tp-id='gen-div-fa3ca001-daa6-471f-9203-b4544f3d626b'
            >
              {description}
            </div>
            <div
              className='flex flex-col'
              data-tp-id='gen-div-fad89050-c868-4cd6-9793-4011f4c317c9'
            >
              {leftLinks?.map(({ title, to, chevron = true }) => (
                <Link
                  eventProps={{ location: `header-${name}` }}
                  key={title}
                  className='py-s'
                  to={to}
                  data-tp-id='gen-Link-b133a9a7-c0e3-4869-9188-54fcca550d79'
                >
                  {title}{' '}
                  {chevron && <Chevron className='rotate-90' size='m' />}
                </Link>
              ))}
            </div>
          </div>
          <div
            className='lg:px-m'
            data-tp-id='gen-div-943940de-f391-4c20-9e2a-746ab97a8902'
          >
            <div
              className='font-t-s lg:py-m'
              data-tp-id='gen-div-a14a96b1-446f-47ff-a0b7-f86e750b9e73'
            >
              {rightTitle}
            </div>
            <div
              className='grid gap-x-m lg:grid-cols-2'
              data-tp-id='gen-div-9164828b-0369-4cf5-a58a-e720526a3f00'
            >
              {rightLinks?.map(({ title, to }) => (
                <Link
                  eventProps={{ location: `header-${name}` }}
                  key={title}
                  className='py-s last:pb-0'
                  to={to}
                  data-tp-id='gen-Link-7fc7e737-71b4-40f3-bab3-87120da46cfe'
                >
                  {title}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const getUserName = (user) => {
  if (user) {
    const name = user.name ? user.name.split(' ')[0] : user.email;
    return name.length > 10 ? `${name.substring(0, 10)}...` : name;
  }
  return null;
};

const LogInSignUp = ({ logIn }) => {
  return (
    <>
      <Button
        variant='custom'
        onClick={logIn}
        className='mr-s !p-0'
        eventProps={{ cta: 'Launch Login', location: 'header' }}
        data-tp-id='gen-Button-700ae71e-e6a0-4d65-816c-1c3fe4e7354c'
      >
        Sign In
      </Button>
    </>
  );
};

const Header = React.memo(
  ({
    menuOpen,
    toggleDropdown,
    onLocationSearch,
    loggedInMenuItems,
    lowerMenuItems,
    onCurrentLocation,
    user,
    logIn,
    signUp,
    showLogin,
    isPlus,
    authenticating,
    showHeaderAgentPhNo,
    showHeaderPhNo,
    showSearch,
    showLowerMenu = true,
    ...props
  }) => {
    const dropdownMenuRef = useRef(null);
    const handleHeaderMenuBlur = (e) => {
      // trigger blur event handler only if focus is on an element outside dropdown, mind it
      if (
        menuOpen &&
        !dropdownMenuRef.current?.contains(e.relatedTarget) &&
        !authenticating
      ) {
        toggleDropdown();
      }
    };

    const MenuIcon = menuOpen ? Close : Menu;

    const [isOpenLapTop, setIsOpenLaptop] = useState('');
    const [isOpen, setIsOpen] = useState('');

    const refLowerLaptop = useRef(null);

    const handleHeaderLowerMenuBlur = (e) => {
      if (isOpenLapTop && !refLowerLaptop.current?.contains(e.relatedTarget)) {
        setIsOpenLaptop(null);
      }
    };

    const headerAgentPhProps = {
      showHeaderAgentPhNo: showHeaderAgentPhNo,
      TooltipWrapper: NumbersTooltip,
    };

    return (
      // tabIndex necessary for onBlur to work
      <nav
        tabIndex='-1'
        ref={dropdownMenuRef}
        onBlur={handleHeaderMenuBlur}
        className={cx(
          'top-0',
          'outline-0',
          'z-nav',
          'w-full',
          !showLowerMenu && 'border-b',
          isPlus ? 'bg-harvest-lighter-90' : 'bg-white',
          menuOpen && 'ul:fixed ul:h-full ul:overflow-auto'
        )}
        {...props}
      >
        {showHeaderPhNo && <HeaderAgentPhNo isTopBar {...headerAgentPhProps} />}

        <div
          className={cx(
            'flex',
            'flex-row',
            'items-center',
            'justify-between',
            !isPlus && 'border-slate-lighter-90',
            'px-m',
            'md:px-l',
            'h-[72px]',
            'border-b lg:border-0'
          )}
          data-tp-id='gen-div-8dcf7d06-5700-41a5-860c-c5ff398110e9'
        >
          <Link
            to='/'
            eventProps={{ icon: 'logo', location: 'header' }}
            className={cx(
              'block',
              'flex-col',
              'items-center',
              // 'lg:w-[198px]', was used to center with phone number, removed phone for now so this is not needed
              'mr-m'
            )}
            data-tp-id='gen-Link-bae9ac42-5318-4015-90a6-d6746fb6b13a'
          >
            <Logo className='hidden lg:block' />
            <Seniorly className='lg:hidden' size='xl' />
          </Link>

          {showSearch && (
            <SearchBoxContainer
              onCurrentLocation={onCurrentLocation}
              onLocationSearch={onLocationSearch}
              className={cx('w-full max-w-[26rem] md:p-0 lg:block')}
              layout='header'
              width='100%'
              padding={['regular', 0]}
              visibility={menuOpen ? 'hidden' : 'visible'}
              include='community'
              placeholder='Search by location or community name'
              eventProps={{
                location: 'header',
              }}
            />
          )}

          <div
            className='ml-m flex justify-end lg:hidden'
            data-tp-id='gen-div-4c6f664f-614b-4925-9873-ae6d3a407fcb'
          >
            {showHeaderPhNo && (
              <HeaderAgentPhNo
                className='hidden md:block'
                {...headerAgentPhProps}
              />
            )}

            <div className='flex flex-col justify-center'>
              <MenuIcon
                onClick={toggleDropdown}
                className='cursor-pointer text-slate-base'
                data-testid='MenuIcon'
              />
            </div>
          </div>

          <div
            className={cx(
              'children-nowrap hidden h-[80px]',
              'items-center lg:flex'
            )}
            data-tp-id='gen-div-ae1210ca-8d90-440b-b0e8-63e0b4a6b85f'
          >
            {showHeaderPhNo && <HeaderAgentPhNo {...headerAgentPhProps} />}

            {showLogin && !user && (
              <LogInSignUp logIn={logIn} signUp={signUp} />
            )}
            <HeaderLoggedInArea
              showLogin={showLogin && user}
              userName={getUserName(user)}
              isCustomer={userExact(user, CUSTOMER_ROLE)}
              toggleDropdown={toggleDropdown}
              menuOpen={menuOpen}
            />
          </div>
        </div>

        {showLowerMenu && (
          <div
            className={cx(
              'flex',
              'hidden',
              'flex-row',
              'items-center',
              'justify-center',
              'px-m',
              'h-12',
              'lg:flex',
              'border-b'
            )}
            data-tp-id='gen-div-8dcf7d06-5700-41a5-860c-c5ff398110e9'
          >
            <div
              className='children-nowrap hidden items-center lg:flex'
              data-tp-id='gen-div-ae1210ca-8d90-440b-b0e8-63e0b4a6b85f'
              tabIndex='-1'
              onBlur={handleHeaderLowerMenuBlur}
              ref={refLowerLaptop}
            >
              {lowerMenuItems.map((innerProps) => (
                <LowerMenuItem
                  key={innerProps.name}
                  isOpen={isOpenLapTop}
                  setIsOpen={setIsOpenLaptop}
                  {...innerProps}
                />
              ))}
            </div>
          </div>
        )}

        {menuOpen && (
          <DropdownMenu
            loggedInMenuItems={loggedInMenuItems}
            lowerMenuItems={lowerMenuItems}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        )}
      </nav>
    );
  }
);

Header.propTypes = {
  menuOpen: bool,
  toggleDropdown: func,
  onLocationSearch: func,
  onLogoClick: func,
  onCurrentLocation: func,
  headerItems: arrayOf(
    shape({
      name: string.isRequired,
      to: string,
      onClick: func,
    })
  ).isRequired,
  loggedInMenuItems: arrayOf(
    shape({
      name: string.isRequired,
      to: string,
      onClick: func,
      hideInBigScreen: bool,
      icon: string,
      section: oneOf([1, 2, 3, 4]),
    })
  ),
  lowerMenuItems: array,
};

export default Header;
